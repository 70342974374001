<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          {{ $t('futures.agente.assinanteDesempenho.titleFiltros') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('futures.agente.assinanteDesempenho.tableHeader.email')"
              label-for="palavraChave"
            >
              <b-form-input
                id="palavraChave"
                v-model="palavraChaveFilter"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('futures.agente.assinanteDesempenho.dataInicial') }}</label>
            <flat-pickr
              v-model="dataBuscaFilter.inicio"
              class="form-control"
              :config="{ altInput: true, enableTime: false, altFormat: 'd/m/Y', locale: Portuguese}"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('futures.agente.assinanteDesempenho.dataFinal') }}</label>
            <flat-pickr
              v-model="dataBuscaFilter.fim"
              class="form-control"
              :config="{ altInput: true, enableTime: false, altFormat: 'd/m/Y', locale: Portuguese,
              }"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label style="visibility:hidden">{{ $t('futures.agente.assinanteDesempenho.tableHeader.acao') }}</label>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control"
              @click.prevent="buscarFiltro"
            >
              {{ $t('futures.agente.assinanteDesempenho.pesquisarBtn') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-table
        ref="refListTable"
        :busy="loadingOverlay"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('futures.emptyText')"
        :sort-desc.sync="isSortDirDesc"
        striped
        style="min-height: 250px"
      >

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #cell(total)="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(lucroLiquido)="data">
          <b-badge :variant="parseFloat(data.value) >=0 ? 'light-success' : 'light-danger'">
            {{ toFixed(data.value, 2) }} USDT
          </b-badge>
        </template>

        <template #cell(totalTaxas)="data">
          <template v-if="data.value">
            <div class="text-nowrap">
              {{ toFixed(data.value, 2) }} <small>USDT</small>
            </div>
          </template>
        </template>

        <template #cell(pnlTotal)="data">
          <template v-if="data.value">
            <div class="text-nowrap">
              {{ toFixed(data.value, 2) }} <small>USDT</small>
            </div>
          </template>
        </template>

        <template #cell(statusAssinatura)="data">
          <b-badge :variant="data.value == 'Ativo'? 'light-success': 'light-danger' ">
            {{ data.value }}
          </b-badge>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('futures.textMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BPagination, BRow, BCol, BCardBody, BButton, BBadge, BCardHeader, BFormGroup, BFormInput, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import { formatDateBR, toIsoStringTimeZone } from '@/utils/filter'
import assinanteDesempenhoStoreModule from './assinanteDesempenhoStoreModule'

const AGENTE_RELATORIO_DESEMPENHO_ASSINANTE_FUT_STORE_MODULE_NAME = 'agente-relatorio-desempenho-assinante-fut'

export default {

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  components: {
    BCard,
    BCardHeader,
    BTable,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    flatPickr,
    BSpinner,
  },

  mixins: [utilsMixin],

  data() {
    return {
      Portuguese,
      formatDateBR,
      perPage: 15,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'nomeUsuario,asc',
      isSortDirDesc: false,
      loadingOverlay: false,
      dataList: [],
      dataBuscaFilter: {
        inicio: new Date(new Date().getFullYear(), new Date().getMonth(), '01'), // primeiro dia do mes atual
        fim: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      },
      statusAgenteFilter: null,
      palavraChaveFilter: null,
    }
  },

  computed: {

    statusAgenteOptions() {
      return [
        { label: this.$i18n.t('futures.agente.assinanteDesempenho.todos'), value: null },
        { label: this.$i18n.t('futures.agente.assinanteDesempenho.inativo'), value: '3' },
        { label: this.$i18n.t('futures.agente.assinanteDesempenho.ativo'), value: '1' },
      ]
    },

    tableColumns() {
      return [
        { key: 'nomeUsuario', label: this.$i18n.t('futures.agente.assinanteDesempenho.tableHeader.nome') },
        { key: 'emailUsuario', label: this.$i18n.t('futures.agente.assinanteDesempenho.tableHeader.email') },
        { key: 'statusAssinatura', label: this.$i18n.t('futures.agente.assinanteDesempenho.tableHeader.statusAssinatura') },
        { key: 'planoNome', label: this.$i18n.t('futures.agente.assinanteDesempenho.tableHeader.planoNome') },
        { key: 'planoVariacaoNome', label: this.$i18n.t('futures.agente.assinanteDesempenho.tableHeader.planoVariacaoNome') },
        { key: 'lucroLiquido', label: this.$i18n.t('futures.agente.assinanteDesempenho.tableHeader.lucroLiquido') },
        { key: 'pnlTotal', label: this.$i18n.t('futures.agente.assinanteDesempenho.tableHeader.totalPnl') },
        { key: 'totalTaxas', label: this.$i18n.t('futures.agente.assinanteDesempenho.tableHeader.totalTaxas') },
        { key: 'total', label: this.$i18n.t('futures.agente.assinanteDesempenho.tableHeader.totalOperacao') },
      ]
    },

    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },

  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(AGENTE_RELATORIO_DESEMPENHO_ASSINANTE_FUT_STORE_MODULE_NAME)) store.registerModule(AGENTE_RELATORIO_DESEMPENHO_ASSINANTE_FUT_STORE_MODULE_NAME, assinanteDesempenhoStoreModule)
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(AGENTE_RELATORIO_DESEMPENHO_ASSINANTE_FUT_STORE_MODULE_NAME)) store.unregisterModule(AGENTE_RELATORIO_DESEMPENHO_ASSINANTE_FUT_STORE_MODULE_NAME)
  },

  methods: {
    buscarFiltro() {
      this.currentPage = 1
      this.fetchList()
    },

    fetchList() {
      this.loadingOverlay = true

      if (!this.statusAgenteFilter) {
        this.statusAgenteFilter = this.statusAgenteOptions.find(option => option.value === '1')
      }

      let dataInicio = this.dataBuscaFilter.inicio
      if (typeof (dataInicio) === 'string' || dataInicio instanceof String) {
        dataInicio = toIsoStringTimeZone(new Date(dataInicio.split('-')[0], dataInicio.split('-')[1] - 1, dataInicio.split('-')[2]))
      }

      let dataFim = new Date(this.dataBuscaFilter.fim)

      if (typeof (dataFim) === 'string' || dataFim instanceof String) {
        const dataFimTemp = new Date(dataFim.split('-')[0], dataFim.split('-')[1] - 1, dataFim.split('-')[2])
        dataFim = new Date(dataFimTemp.setDate(dataFimTemp.getDate() + 1)).toISOString()
      } else {
        dataFim = new Date(dataFim.setDate(dataFim.getDate() + 1))
      }

      store
        .dispatch('agente-relatorio-desempenho-assinante-fut/getDesempenhoAssinantes', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          dataInicio,
          dataFim,
          status: this.statusAgenteFilter.value,
          email: this.palavraChaveFilter,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(() => {
        }).finally(() => {
          this.loadingOverlay = false
        })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
