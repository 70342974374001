import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getDesempenhoAssinantes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/relatorio/agente/desempenho-assinante', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

  },
}
